<template lang="pug">
div
  app-button(
    type="primary"
    label="Invitar"
    class="small"
    :customStyle="{width:'auto'}"
    :handler="showModal")
  a-modal(
    :footer="null"
    v-model="visible")
    app-h3(
      content="Información de el invitado")
    a-form(
      :footer="null"
      :form="form"
      @submit.prevent="sendInvitation")
      a-form-item
        app-text(content="Nombre")
        a-input(
          v-decorator="['name',{ rules: [{required: true, message: 'El nombre es necesario'}] }]"
          type="text")
      a-form-item
        app-text(content="Email")
        a-input(
          v-decorator="['email',{ rules: [{required: true, message: 'El correo electrónico es necesario'}, {type: 'email', message: 'Ingresa un correo valido'}] }]"
          type="email")
      app-button(
        :handler="hideModal"
        label="Cancelar")
      app-button(
        htmlType="submit"
        type="primary"
        label="Continuar")
</template>
<script>
//Import atoms components
import AppButton from "@/components/atoms/AppButton.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppH3 from "@/components/atoms/AppH3.vue";

//Import helpers
import JwtService from "@/common/jwt.service.js";

import { SalesRoomService } from "@/common/api.service.js";

export default {
  name: "InviteForm",
  components: {
    AppButton,
    AppText,
    AppH3
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      visible: false
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    sendInvitation() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const { name, email } = values;
          let client = {
            name: name,
            email: email
          };

          let invited_by = this.$session.get("user").id;

          let attendant_token = JwtService.getToken();

          let attendants = [];

          attendants[0] = {
            client: client,
            invited_by: invited_by
          };

          let request = {};

          request.attendants = attendants;

          request.attendant_token = attendant_token;

          SalesRoomService.inviteClient(
            this.$session.get("ShowRoomClient").id,
            request
          )
            .then(() => {
              this.$notification.open({
                message: "Invitación enviada",
                description: "El invitado debe revisar su correo electrónico",
                icon: <a-icon type="smile" />
              });
            })
            .catch(() => {
              this.$notification["warning"]({
                message: "Usuario ya invitado",
                description:
                  "Ingresa un correo electrónico de una persona que ya no este invitada a este ShowRoom"
              });
            });
        }
        this.hideModal();
        this.form.resetFields();
        values;
      });
    }
  }
};
</script>
